import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import IntegrationParams from './components/IntegrationParams';
import store from './store';
import pjson from '../package.json';

const rootId = document.getElementById('lifejourney-results') ? 'lifejourney-results' : 'webbot';
if (rootId !== 'lifejourney-results') console.warn('#lifejourney-results не найден');

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<></>}>
            <IntegrationParams component={App} />
        </Suspense>
    </Provider>,
    document.getElementById(rootId)
);

console.log(
    `%c Life Journey widget version: ${pjson.version} `,
    'background-color: #ffdd2d; color: #333;'
);

// reportWebVitals();
